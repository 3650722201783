import { defineMessage } from "react-intl";

// ============================================================

export const PRESTATIONS_ALIASES_VALUE_CANTINE = "cantine";

// ============================================================

export const PRESTATIONS_SORT_VALUE_DATE_ASC = "created_asc";
export const PRESTATIONS_SORT_VALUE_DATE_DESC = "created_desc";
export const PRESTATIONS_SORT_VALUE_RANDOM = "random";

export const PRESTATIONS_SORTS = {
  [PRESTATIONS_SORT_VALUE_DATE_ASC]: {
    value: PRESTATIONS_SORT_VALUE_DATE_ASC,
    message: defineMessage({ defaultMessage: "Date de création croissante" }),
  },
  [PRESTATIONS_SORT_VALUE_DATE_DESC]: {
    value: PRESTATIONS_SORT_VALUE_DATE_DESC,
    message: defineMessage({ defaultMessage: "Date de création décroissante" }),
  },
  [PRESTATIONS_SORT_VALUE_RANDOM]: {
    value: PRESTATIONS_SORT_VALUE_RANDOM,
    message: defineMessage({ defaultMessage: "Aléatoire" }),
  },
};

export const PRESTATIONS_SORT_LIST = Object.values(PRESTATIONS_SORTS);

// ============================================================

export const PRESTATIONS_PRICE_TYPES_VALUE_QUARTER_HOUR = "quarter_hour";
export const PRESTATIONS_PRICE_TYPES_VALUE_HALF_HOUR = "half_hour";
export const PRESTATIONS_PRICE_TYPES_VALUE_HOUR = "hour";
export const PRESTATIONS_PRICE_TYPES_VALUE_SESSION = "day";

export const PRESTATIONS_PRICE_TYPES = {
  [PRESTATIONS_PRICE_TYPES_VALUE_QUARTER_HOUR]: {
    value: PRESTATIONS_PRICE_TYPES_VALUE_QUARTER_HOUR,
    label: defineMessage({ defaultMessage: "Quart d'heure" }),
  },
  [PRESTATIONS_PRICE_TYPES_VALUE_HALF_HOUR]: {
    value: PRESTATIONS_PRICE_TYPES_VALUE_HALF_HOUR,
    label: defineMessage({ defaultMessage: "Demi heure" }),
  },
  [PRESTATIONS_PRICE_TYPES_VALUE_HOUR]: {
    value: PRESTATIONS_PRICE_TYPES_VALUE_HOUR,
    label: defineMessage({ defaultMessage: "Heure" }),
  },
  [PRESTATIONS_PRICE_TYPES_VALUE_SESSION]: {
    value: PRESTATIONS_PRICE_TYPES_VALUE_SESSION,
    label: defineMessage({ defaultMessage: "Séance" }),
  },
};

export const PRESTATIONS_PRICE_TYPES_LIST = Object.values(
  PRESTATIONS_PRICE_TYPES,
);

// ============================================================

export const PRESTATIONS_REGISTRATION_TYPES_VALUE_RESTRICTED = "restricted";
export const PRESTATIONS_REGISTRATION_TYPES_VALUE_FREE = "free";

export const PRESTATIONS_REGISTRATION_TYPES = {
  [PRESTATIONS_REGISTRATION_TYPES_VALUE_RESTRICTED]: {
    value: PRESTATIONS_REGISTRATION_TYPES_VALUE_RESTRICTED,
    label: defineMessage({ defaultMessage: "Restreinte" }),
  },
  [PRESTATIONS_REGISTRATION_TYPES_VALUE_FREE]: {
    value: PRESTATIONS_REGISTRATION_TYPES_VALUE_FREE,
    label: defineMessage({ defaultMessage: "Automatique" }),
  },
};

export const PRESTATIONS_REGISTRATION_TYPES_LIST = Object.values(
  PRESTATIONS_REGISTRATION_TYPES,
);

// ============================================================

export const PRESTATIONS_REGISTRATION_MODES_VALUE_FREE = "free";
export const PRESTATIONS_REGISTRATION_MODES_VALUE_FULL = "full";

/**
 * @type {Object<string, {
 * value: string,
 * label: import("react-intl").MessageDescriptor,
 * description: import("react-intl").MessageDescriptor,
 * descriptionUser: import("react-intl").MessageDescriptor,
 * availability: import("react-intl").MessageDescriptor,
 * }>}
 */
export const PRESTATIONS_REGISTRATION_MODES = {
  [PRESTATIONS_REGISTRATION_MODES_VALUE_FREE]: {
    value: PRESTATIONS_REGISTRATION_MODES_VALUE_FREE,
    label: defineMessage({ defaultMessage: "Par date" }),
    description: defineMessage({
      defaultMessage: "Inscription aux séances sur planning",
    }),
    descriptionUser: defineMessage({
      defaultMessage:
        "Inscription sur planning dans votre espace adhérent, en fonction des disponibilités.",
    }),
    availability: defineMessage({
      defaultMessage: "sur planning",
    }),
  },
  [PRESTATIONS_REGISTRATION_MODES_VALUE_FULL]: {
    value: PRESTATIONS_REGISTRATION_MODES_VALUE_FULL,
    label: defineMessage({ defaultMessage: "Par session" }),
    description: defineMessage({
      defaultMessage: "Inscription par sessions",
    }),
    descriptionUser: defineMessage({
      defaultMessage: "Inscription aux sessions dans votre espace adhérent",
    }),
    availability: defineMessage({
      defaultMessage: "sessions",
    }),
  },
};

export const PRESTATIONS_REGISTRATION_MODES_LIST = Object.values(
  PRESTATIONS_REGISTRATION_MODES,
);

// ============================================================

export const PRESTATIONS_PRESENCE_MODES_VALUE_START = "start";
export const PRESTATIONS_PRESENCE_MODES_VALUE_END = "end";
export const PRESTATIONS_PRESENCE_MODES_VALUE_BOTH = "both";
export const PRESTATIONS_PRESENCE_MODES_VALUE_SESSION = "session";

export const PRESTATIONS_PRESENCE_MODES = {
  [PRESTATIONS_PRESENCE_MODES_VALUE_START]: {
    value: PRESTATIONS_PRESENCE_MODES_VALUE_START,
    label: defineMessage({ defaultMessage: "Début" }),
  },
  [PRESTATIONS_PRESENCE_MODES_VALUE_END]: {
    value: PRESTATIONS_PRESENCE_MODES_VALUE_END,
    label: defineMessage({ defaultMessage: "Fin" }),
  },
  [PRESTATIONS_PRESENCE_MODES_VALUE_BOTH]: {
    value: PRESTATIONS_PRESENCE_MODES_VALUE_BOTH,
    label: defineMessage({ defaultMessage: "Début et fin" }),
  },
  [PRESTATIONS_PRESENCE_MODES_VALUE_SESSION]: {
    value: PRESTATIONS_PRESENCE_MODES_VALUE_SESSION,
    label: defineMessage({ defaultMessage: "Séance" }),
  },
};

export const PRESTATIONS_PRESENCE_MODES_LIST = Object.values(
  PRESTATIONS_PRESENCE_MODES,
);

// ============================================================

export const PRESTATIONS_PRICES_TYPES_VALUE_DEFAULT_PRICE = "default_price";
export const PRESTATIONS_PRICES_TYPES_VALUE_PRICE_RESIDENT_NORMAL =
  "price_resident_normal";
export const PRESTATIONS_PRICES_TYPES_VALUE_PRICE_RESIDENT_REDUCE =
  "price_resident_reduce";
export const PRESTATIONS_PRICES_TYPES_VALUE_PRICE_COMMUNITY_NORMAL =
  "price_community_normal";
export const PRESTATIONS_PRICES_TYPES_VALUE_PRICE_COMMUNITY_REDUCE =
  "price_community_reduce";
export const PRESTATIONS_PRICES_TYPES_VALUE_PRICE_OUTSIDE_NORMAL =
  "price_outside_normal";
export const PRESTATIONS_PRICES_TYPES_VALUE_PRICE_OUTSIDE_REDUCE =
  "price_outside_reduce";
export const PRESTATIONS_PRICES_TYPES_VALUE_PRICE_PARTNER_NORMAL =
  "price_partner_normal";
export const PRESTATIONS_PRICES_TYPES_VALUE_PRICE_PARTNER_REDUCE =
  "price_partner_reduce";

/**
 * @type {Object<string, {
 * value: string,
 * label: import("react-intl").MessageDescriptor,
 * isReduced: boolean,
 * }>}
 */
export const PRESTATIONS_PRICES_TYPES = {
  [PRESTATIONS_PRICES_TYPES_VALUE_DEFAULT_PRICE]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_DEFAULT_PRICE,
    label: defineMessage({ defaultMessage: "Tarif par défaut" }),
    isReduced: false,
  },
  [PRESTATIONS_PRICES_TYPES_VALUE_PRICE_RESIDENT_NORMAL]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_PRICE_RESIDENT_NORMAL,
    label: defineMessage({ defaultMessage: "Tarif résident de la commune" }),
    isReduced: false,
  },
  [PRESTATIONS_PRICES_TYPES_VALUE_PRICE_RESIDENT_REDUCE]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_PRICE_RESIDENT_REDUCE,
    label: defineMessage({
      defaultMessage: "Tarif résident de la commune (réduit)",
    }),
    isReduced: true,
  },
  [PRESTATIONS_PRICES_TYPES_VALUE_PRICE_COMMUNITY_NORMAL]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_PRICE_COMMUNITY_NORMAL,
    label: defineMessage({
      defaultMessage: "Tarif résident de la communauté de communes",
    }),
    isReduced: false,
  },
  [PRESTATIONS_PRICES_TYPES_VALUE_PRICE_COMMUNITY_REDUCE]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_PRICE_COMMUNITY_REDUCE,
    label: defineMessage({
      defaultMessage: "Tarif résident de la communauté de communes (réduit)",
    }),
    isReduced: true,
  },
  [PRESTATIONS_PRICES_TYPES_VALUE_PRICE_OUTSIDE_NORMAL]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_PRICE_OUTSIDE_NORMAL,
    label: defineMessage({ defaultMessage: "Tarif extérieur" }),
    isReduced: false,
  },
  [PRESTATIONS_PRICES_TYPES_VALUE_PRICE_OUTSIDE_REDUCE]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_PRICE_OUTSIDE_REDUCE,
    label: defineMessage({ defaultMessage: "Tarif extérieur (réduit)" }),
    isReduced: true,
  },
  [PRESTATIONS_PRICES_TYPES_VALUE_PRICE_PARTNER_NORMAL]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_PRICE_PARTNER_NORMAL,
    label: defineMessage({ defaultMessage: "Tarif pour les chantrainois" }),
    isReduced: false,
  },
  [PRESTATIONS_PRICES_TYPES_VALUE_PRICE_PARTNER_REDUCE]: {
    value: PRESTATIONS_PRICES_TYPES_VALUE_PRICE_PARTNER_REDUCE,
    label: defineMessage({
      defaultMessage: "Tarif pour les chantrainois (réduit)",
    }),
    isReduced: true,
  },
};

export const PRESTATIONS_PRICES_TYPES_LIST = Object.values(
  PRESTATIONS_PRICES_TYPES,
);

// ============================================================

export const PRESTATIONS_HOLDER_TYPES_VALUE_CHILD = "child";
export const PRESTATIONS_HOLDER_TYPES_VALUE_TEENAGER = "teenager";
export const PRESTATIONS_HOLDER_TYPES_VALUE_MEMBER = "member";
export const PRESTATIONS_HOLDER_TYPES_VALUE_SENIOR = "senior";

/**
 * @typedef {object} PrestationHolderTypeDefinition
 * @property {import("../types/Prestation").PrestationHolderType} value
 * @property {import("react-intl").MessageDescriptor} label
 * @property {import("react-intl").MessageDescriptor} labelWithAgeRange
 * @property {import("react-intl").MessageDescriptor} labelPlural
 * @property {import("react-intl").MessageDescriptor} ageRange
 * @property {import("react-intl").MessageDescriptor} description
 * @property {string} frontSrc
 */

/** @type {Object<string, PrestationHolderTypeDefinition>} */
export const PRESTATIONS_HOLDER_TYPES = {
  [PRESTATIONS_HOLDER_TYPES_VALUE_CHILD]: {
    value: PRESTATIONS_HOLDER_TYPES_VALUE_CHILD,
    label: defineMessage({ defaultMessage: "enfant" }),
    labelWithAgeRange: defineMessage({ defaultMessage: "enfant (5 à 11 ans)" }),
    labelPlural: defineMessage({ defaultMessage: "enfants" }),
    ageRange: defineMessage({ defaultMessage: "de 5 à 11 ans" }),
    description: defineMessage({
      defaultMessage:
        "Une personne pouvant accéder aux activités réservées aux enfants.",
    }),
    frontSrc: "prestations/holder-types/child.png",
  },
  [PRESTATIONS_HOLDER_TYPES_VALUE_TEENAGER]: {
    value: PRESTATIONS_HOLDER_TYPES_VALUE_TEENAGER,
    label: defineMessage({ defaultMessage: "adolescent" }),
    labelWithAgeRange: defineMessage({
      defaultMessage: "adolescent (12 à 18 ans)",
    }),
    labelPlural: defineMessage({ defaultMessage: "adolescents" }),
    ageRange: defineMessage({ defaultMessage: "de 12 à 18 ans" }),
    description: defineMessage({
      defaultMessage:
        "Une personne pouvant accéder aux activités réservées aux enfants.",
    }),
    frontSrc: "prestations/holder-types/teenager.png",
  },
  [PRESTATIONS_HOLDER_TYPES_VALUE_MEMBER]: {
    value: PRESTATIONS_HOLDER_TYPES_VALUE_MEMBER,
    label: defineMessage({ defaultMessage: "adulte" }),
    labelWithAgeRange: defineMessage({
      defaultMessage: "adulte (19 à 59 ans)",
    }),
    labelPlural: defineMessage({ defaultMessage: "adultes" }),
    ageRange: defineMessage({ defaultMessage: "de 19 à 59 ans" }),
    description: defineMessage({
      defaultMessage:
        "Une personne pouvant accéder aux activités réservées aux adultes. Elle ne pourra pas accéder aux activités réservées aux enfants.",
    }),
    frontSrc: "prestations/holder-types/member.png",
  },
  [PRESTATIONS_HOLDER_TYPES_VALUE_SENIOR]: {
    value: PRESTATIONS_HOLDER_TYPES_VALUE_SENIOR,
    label: defineMessage({ defaultMessage: "senior" }),
    labelWithAgeRange: defineMessage({
      defaultMessage: "senior (à partir de 60 ans)",
    }),
    labelPlural: defineMessage({ defaultMessage: "seniors" }),
    ageRange: defineMessage({ defaultMessage: "à partir de 60 ans" }),
    description: defineMessage({
      defaultMessage:
        "Une personne pouvant accéder aux activités réservées aux adultes. Elle ne pourra pas accéder aux activités réservées aux enfants.",
    }),
    frontSrc: "prestations/holder-types/senior.png",
  },
};

export const PRESTATIONS_HOLDER_TYPES_LIST = Object.values(
  PRESTATIONS_HOLDER_TYPES,
);

// ============================================================

export const PRESTATIONS_VISIBILITY_VALUE_PUBLIC = "public";
export const PRESTATIONS_VISIBILITY_VALUE_PRIVATE = "private";

/** @type {Record<import("../types/Prestation").PrestationVisibility, {value: import("../types/Prestation").PrestationVisibility, label: import("react-intl").MessageDescriptor, description: import("react-intl").MessageDescriptor}>} */
export const PRESTATIONS_VISIBILITIES = {
  [PRESTATIONS_VISIBILITY_VALUE_PUBLIC]: {
    value: PRESTATIONS_VISIBILITY_VALUE_PUBLIC,
    label: defineMessage({ defaultMessage: "publique" }),
    description: defineMessage({
      defaultMessage:
        "Une prestation publique est visible sur la partie publique du site internet.",
    }),
  },
  [PRESTATIONS_VISIBILITY_VALUE_PRIVATE]: {
    value: PRESTATIONS_VISIBILITY_VALUE_PRIVATE,
    label: defineMessage({ defaultMessage: "privée" }),
    description: defineMessage({
      defaultMessage:
        "Une prestation privée n'est visible que sur la partie privée du site internet.",
    }),
  },
};

export const PRESTATIONS_VISIBILITIES_LIST = Object.values(
  PRESTATIONS_VISIBILITIES,
);

// ============================================================

export const PRESTATIONS_PRICING_GRID_TYPE_VALUE_DEFAULT = "default";
export const PRESTATIONS_PRICING_GRID_TYPE_VALUE_DEPENDENT_CHILDREN =
  "dependent_children";
export const PRESTATIONS_PRICING_GRID_TYPE_VALUE_DURATION = "duration";

export const PRESTATIONS_PRICING_GRID_TYPES = {
  [PRESTATIONS_PRICING_GRID_TYPE_VALUE_DEFAULT]: {
    value: PRESTATIONS_PRICING_GRID_TYPE_VALUE_DEFAULT,
    label: defineMessage({ defaultMessage: "Normale" }),
  },
  [PRESTATIONS_PRICING_GRID_TYPE_VALUE_DEPENDENT_CHILDREN]: {
    value: PRESTATIONS_PRICING_GRID_TYPE_VALUE_DEPENDENT_CHILDREN,
    label: defineMessage({
      defaultMessage: "Dépendant des enfants (halte garderie)",
    }),
  },
  [PRESTATIONS_PRICING_GRID_TYPE_VALUE_DURATION]: {
    value: PRESTATIONS_PRICING_GRID_TYPE_VALUE_DURATION,
    label: defineMessage({
      defaultMessage: "Dégressif par jour souscrit (vacances)",
    }),
  },
};

export const PRESTATIONS_PRICING_GRID_TYPES_LIST = Object.values(
  PRESTATIONS_PRICING_GRID_TYPES,
);
